






















































































































































































































































































































































































































































































































































import {
  defineComponent,
  onBeforeMount,
  onMounted,
  Ref,
  ref,
  watch,
} from '@vue/composition-api'
import { useActions, useState } from '@u3u/vue-hooks'
import Breadcrumb from '@/components/Breadcrumb.vue'
import Header from '@/components/Header.vue'
import RelevesIndexTable from '@/components/commune/components/RelevesIndexTable.vue'
import CheckboxSelector from '@/components/commune/components/filter/CheckboxSelector.vue'
import { AdresseEan, GenericObject } from '@/inc/types'
import panelAccordion from '@/components/ui/PanelAccordion.vue'
import CustomPagination from '@/components/commune/components/filter/CustomPagination.vue'
import { removeAccents } from '@/inc/utils/utils'
import PointContact from '@/components/commune/components/PointContact.vue'
import * as check from '@/composables/commune'

const { VUE_APP_DOMAIN } = process.env

export default defineComponent({
  name: 'commune-conso',
  components: {
    Breadcrumb,
    Header,
    RelevesIndexTable,
    CheckboxSelector,
    panelAccordion,
    CustomPagination,
    PointContact,
  },
  setup(props, ctx) {
    const { i18n } = useState('commune', ['i18n'])
    // const { getEansSmart } = useGetters('commune', ['getEansSmart'])
    const { FETCH_EANS_SMART } = useActions('commune', ['FETCH_EANS_SMART'])
    const breadcrumbPage = [
      {
        label: i18n.value.communeConsommation.title?.replace(/<[^>]*>/g, ''),
        url: '',
      },
    ]
    const search: Ref<string> = ref('')
    const isLoading: Ref<boolean> = ref(true)
    const dataList: Ref<AdresseEan[]> = ref([])
    let eans: string[] = []
    let compteurs: string[] = []
    let rues: string[] = []
    let energies: string[] = []
    const items: Ref<AdresseEan[]> = ref(dataList.value)
    const paginatedItems: Ref<AdresseEan[]> = ref([])
    const activePanels: Ref<number[]> = ref([])
    const sortAdrOrder: Ref<string> = ref('ASC')
    const sortEanOrder: Ref<string> = ref('')
    const pageSize = 10
    const currentPage: Ref<number> = ref(1)
    const typesFluides: GenericObject[] = []

    const sortItemsByOrder = (field: string | null = 'adresse') => {
      if (field === 'adresse') {
        items.value.sort((a, b) => {
          if (a.adresse.rue < b.adresse.rue) {
            return sortAdrOrder.value === 'DESC' ? 1 : -1
          }
          if (a.adresse.rue > b.adresse.rue) {
            return sortAdrOrder.value === 'DESC' ? -1 : 1
          }

          return 0
        })
      } else if (field === 'ean') {
        items.value.sort((a, b) => {
          if (a.eans.length < b.eans.length) {
            return sortEanOrder.value === 'DESC' ? 1 : -1
          }
          if (a.eans.length > b.eans.length) {
            return sortEanOrder.value === 'DESC' ? -1 : 1
          }

          return 0
        })
      }

      pageChanged({ currentPage: 1 })
    }

    onBeforeMount(async () => {
      isLoading.value = true
      dataList.value = check.getRelevesIndexSmartData()
      if (!dataList.value || dataList.value.length === 0) {
        await FETCH_EANS_SMART()
        dataList.value = check.getRelevesIndexSmartData()
      }
      dataList.value.forEach(item => {
        const key = item.eans[0].ean
        typesFluides[key] = []
        item.eans.forEach(ean => {
          if (eans.indexOf(ean.ean) === -1) {
            eans.push(ean.ean)
          }
          if (compteurs.indexOf(ean.numCpt) === -1) {
            compteurs.push(ean.numCpt)
          }
          if (energies.indexOf(ean.type) === -1) {
            energies.push(ean.type)
          }
          if (typesFluides[key].indexOf(ean.type) === -1) {
            typesFluides[key].push(ean.type)
          }
        })
        if (rues.indexOf(item.adresse.rue) === -1) {
          rues.push(item.adresse.rue)
        }
      })

      eans.sort()
      compteurs.sort()
      energies.sort()
      rues.sort()
      items.value = dataList.value
      sortItemsByOrder()
      isLoading.value = false
    })

    onMounted(() => {
      console.log('Mounted Consommations')
    })

    const columns = [
      {
        label: i18n.value.relevesIndex.colonne.ean,
        field: 'ean',
        type: 'string',
        thClass: 'ean',
        tdClass: 'ean',
      },
      {
        label: i18n.value.relevesIndex.colonne.compteur,
        field: 'numCpt',
        type: 'string',
        thClass: 'compteur',
        tdClass: 'compteur',
      },
      {
        label: i18n.value.relevesIndex.colonne.energie,
        field: 'type',
        type: 'string',
        thClass: 'energie',
        tdClass: 'energie',
      },
      {
        label: i18n.value.relevesIndex.colonne.date,
        field: 'date',
        type: 'string',
        thClass: 'date',
        tdClass: 'date',
      },
      {
        label: i18n.value.relevesIndex.colonne.releves,
        field: 'releves',
        type: 'string',
        thClass: 'releves',
        tdClass: 'releves',
      },
      {
        label: '',
        field: 'btn',
        html: true,
        globalSearchDisabled: true,
        sortable: false,
        thClass: 'actions',
        tdClass: 'actions',
      },
    ]

    // Recupere le emit pour filtrer via les eans
    ctx.root.$on('checkbox:selector:eans', data => {
      eans = data
      filterGlobal()
    })
    // Recupere le emit pour filtrer via les compteurs
    ctx.root.$on('checkbox:selector:compteurs', data => {
      compteurs = data
      filterGlobal()
    })
    // Recupere le emit pour filtrer via les rues
    ctx.root.$on('checkbox:selector:rues', data => {
      rues = data
      filterGlobal()
    })
    // Recupere le emit pour filtrer via les energies
    ctx.root.$on('checkbox:selector:energies', data => {
      energies = data
      filterGlobal()
    })

    const pageChanged = page => {
      currentPage.value = page.currentPage
      paginatedItems.value = []
      items.value.forEach(item => {
        paginatedItems.value.push(item)
      })
      let start = (page.currentPage - 1) * pageSize
      if (start >= paginatedItems.value.length || pageSize <= -1) {
        start = 0
        currentPage.value = 1
      }
      const end = start + pageSize
      paginatedItems.value = paginatedItems.value.slice(start, end)
    }

    // List filters
    const filterGlobal = () => {
      items.value = dataList.value
      activePanels.value = []

      // if any search or filter active
      if (
        eans.length > 0 ||
        compteurs.length > 0 ||
        rues.length > 0 ||
        energies.length > 0 ||
        search.value
      ) {
        // check all possible filters
        if (eans.length > 0) {
          items.value = items.value.filter(item =>
            item.eans.some(ean => eans.includes(ean.ean))
          )
        }
        if (compteurs.length > 0) {
          items.value = items.value.filter(item =>
            item.eans.some(ean => compteurs.includes(ean.numCpt))
          )
        }
        if (rues.length > 0) {
          items.value = items.value.filter(item =>
            rues.includes(item.adresse.rue)
          )
        }
        if (energies.length > 0) {
          items.value = items.value.filter(item =>
            item.eans.some(ean => energies.includes(ean.type))
          )
        }

        // if fulltext search
        if (items.value.length > 0 && search.value) {
          items.value = items.value.filter(
            item =>
              item.eans.some(ean =>
                ean.ean.toLowerCase().includes(search.value.toLowerCase())
              ) ||
              item.eans.some(ean =>
                ean.numCpt.toLowerCase().includes(search.value.toLowerCase())
              ) ||
              removeAccents(item.adresse.rue)
                .toLowerCase()
                .includes(removeAccents(search.value).toLowerCase()) ||
              item.adresse.codePostal
                .toString()
                .includes(search.value.toLowerCase()) ||
              removeAccents(item.adresse.localite)
                .toLowerCase()
                .includes(removeAccents(search.value).toLowerCase()) ||
              item.eans.some(ean =>
                removeAccents(ean.type)
                  .toLowerCase()
                  .includes(removeAccents(search.value).toLowerCase())
              )
          )
        }
      }

      // keep sort order
      sortItemsByOrder(sortEanOrder.value === '' ? 'adresse' : 'ean')
      // reset pagination
      pageChanged({ currentPage: 1 })
    }

    const togglePanel = (panel: number) => {
      if (activePanels.value.indexOf(panel) >= 0) {
        activePanels.value.splice(activePanels.value.indexOf(panel), 1)
        activePanels.value = [] // Pour ne garder que un panel ouvert à la fois
      } else {
        activePanels.value = [] // Pour ne garder que un panel ouvert à la fois
        activePanels.value.push(panel)
      }
    }

    const doSearch = () => {
      filterGlobal()
    }

    const sortBy = (field: string) => {
      activePanels.value = []
      if (field === 'adresse') {
        sortEanOrder.value = ''
        if (sortAdrOrder.value === 'ASC') {
          sortAdrOrder.value = 'DESC'
        } else if (sortAdrOrder.value === 'DESC') {
          sortAdrOrder.value = 'ASC'
        } else {
          sortAdrOrder.value = 'ASC'
        }
      } else if (field === 'ean') {
        sortAdrOrder.value = ''
        if (sortEanOrder.value === 'ASC') {
          sortEanOrder.value = 'DESC'
        } else if (sortEanOrder.value === 'DESC') {
          sortEanOrder.value = 'ASC'
        } else {
          sortEanOrder.value = 'ASC'
        }
      }

      sortItemsByOrder(field)
    }

    // To reset the toggled panels when current page change
    watch(currentPage, () => {
      activePanels.value = []
    })

    return {
      isLoading,
      i18n,
      breadcrumbPage,
      columns,
      search,
      items,
      paginatedItems,
      togglePanel,
      activePanels,
      eans,
      compteurs,
      rues,
      energies,
      doSearch,
      sortBy,
      sortAdrOrder,
      sortEanOrder,
      pageChanged,
      pageSize,
      currentPage,
      typesFluides,
      apiDomain: process.env.VUE_APP_API_DOMAIN,
    }
  },
})
