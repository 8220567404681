var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.i18n)?_c('div',{staticClass:"vue-table"},[_c('vue-good-table',{attrs:{"columns":_vm.headers,"pagination-options":{
      enabled: false,
    },"rows":_vm.data,"search-options":{
      enabled: false,
    },"sort-options":{
      initialSortBy: { field: 'ean', type: 'asc' },
    },"zero-state-message":_vm.i18n.noResult,"styleClass":"vgt-table"},scopedSlots:_vm._u([{key:"table-column",fn:function(props){return [_c('CustomHeader',{attrs:{"data":props}})]}},{key:"table-row",fn:function(props){return [(
          _vm.pageDetails === 'index' &&
          props.column.field === 'btn' &&
          _vm.lastColumn
        )?_c('CustomLink',{staticClass:"vgt-actions",attrs:{"content":{
          label: _vm.i18n.relevesIndex.colonne.btnDetails,
          to: {
            name: 'CommuneDetailsCompteur',
            params: {
              ean: props.formattedRow['ean'],
              compteur: props.formattedRow['numCpt'],
            },
          },
        },"modifiers":[
          'btn',
          'big',
          'rectrounded',
          'green',
          'white',
          'uppercase' ]}}):(
          _vm.pageDetails === 'conso' &&
          props.column.field === 'btn' &&
          _vm.lastColumn
        )?_c('CustomLink',{staticClass:"vgt-actions",attrs:{"content":{
          label: _vm.i18n.relevesIndex.colonne.btnDetails,
          url:
            _vm.VUE_APP_MACONSO_DOMAIN + "/consumption?ean=" +
            props.formattedRow['ean'],
          target: '_blank',
        },"modifiers":[
          'btn',
          'big',
          'rectrounded',
          'green',
          'white',
          'uppercase' ],"type":'externalLink'}}):(props.column.field === 'releves')?_c('p',{domProps:{"innerHTML":_vm._s(props.formattedRow[props.column.field])}}):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field]))])]}}],null,false,72556969)},[_c('div',{attrs:{"slot":"emptystate"},slot:"emptystate"},[_c('span',[_vm._v(_vm._s(_vm.i18n.noResult))])])])],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }