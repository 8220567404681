



























































































import { defineComponent } from '@vue/composition-api'
import 'vue-good-table/dist/vue-good-table.css'
import CustomHeader from '@/components/commune/components/filter/CustomHeader.vue'
import { useState } from '@u3u/vue-hooks'
import CustomLink from '@/components/g/CustomLink.vue'
const { VUE_APP_MACONSO_DOMAIN } = process.env

export default defineComponent({
  name: 'vue-table-releves',
  components: {
    CustomLink,
    CustomHeader,
  },
  props: {
    // Headers array of string
    headers: {
      type: Array as () => Array<string>,
      required: true,
    },
    // date for table and list. /!\ entries must be like headers
    data: {
      type: Array as () => Array<any[]>,
      required: true,
    },
    filter: {
      type: Array as () => Array<string>,
      required: false,
      default() {
        return []
      },
    },
    // Last column to have a button for example
    lastColumn: {
      type: Boolean,
      required: false,
      default: false,
    },
    // The page details to adapt the button link
    pageDetails: {
      type: String,
      required: false,
      default: 'index',
    },
  },
  setup() {
    const { i18n } = useState('commune', ['i18n'])

    return {
      i18n,
      VUE_APP_MACONSO_DOMAIN,
    }
  },
})
